import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Input,
  Link,
  Menu,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PaymayaSdkClient from "paymaya-js-sdk";
import { DatePicker } from "@mui/lab";
import { Icon } from "@iconify/react";
import { green } from "@mui/material/colors";
import { CloseIcon } from "../../../theme/overrides/CustomIcons";
import {
  FormProvider,
  RHFCheckbox,
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from "../../../components/hook-form";
import { countries } from "../../../_mock";
import UploadPhoto from "../../../sections/giving_basket/UploadPhoto";
import { CPO_URL, DONATION_TYPE, MAYA_KEY } from "../../../config";
import { fDateDb } from "../../../utils/formatTime";
import { DonationAPI } from "../../../services/donation.services";
import uuidv4 from "../../../utils/uuidv4";
import { CausesAPI } from '../../../services/causes.services';
import DonationForm from "./donation_forms/DonationForm";
import DonationSuccessModal from "./DonationSuccessModal";

const REDIRECT_URL = `${CPO_URL}/resultpage`;

const convertImagesToBase64STring = async (payload) => {
  return new Promise((resolve, reject) => {
    const imagesTotalCount = payload.images.length;
    let imagesCounter = 0;
    if (imagesTotalCount > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        payload.imagesBase64.push(reader.result);
        // eslint-disable-next-line no-plusplus
        imagesCounter++;
        if (imagesCounter < imagesTotalCount) {
          reader.readAsDataURL(payload.images[imagesCounter]);
        } else {
          resolve(payload);
        }
      };
      reader.readAsDataURL(payload.images[imagesCounter]);
    }
  });
};

const discretionProject = [
  {
    id: "3a344482-9d1c-48fb-9ad6-1270f638eb9f",
    name: "Discretionary",
    charityId: "51f5b74f-c4d8-4bcc-8c17-34e3c25eeac8",
    charityName: "CharityPhilippines.org",
  }
]

// const CardPaymentForm = forwardRef(function ({ causes }, ref) { // eslint-disable-line prefer-arrow-callback
//   PaymayaSdkClient.init(MAYA_KEY, true);
//   const resolver = yupResolver(
//     Yup.object().shape({
//       firstName: Yup.string().required("First name is required."),
//       lastName: Yup.string().required("Last name is required."),
//       phone: Yup.string().required("Phone number is required."),
//       email: Yup.string().required("Email is required."),
//       amount: Yup.string().required("Donation amount is required"),
//     })
//   );

//   const defaultValues = {
//     firstName: "",
//     lastName: "",
//     phone: "",
//     email: "",
//     anonymous: false,
//   };

//   const methods = useForm({ defaultValues, resolver });

//   const {
//     reset,
//     setError,
//     handleSubmit,
//     setValue,
//     trigger,
//     watch,
//     clearErrors,
//     formState: { errors, isSubmitting },
//   } = methods;

//   const values = watch();

//   const onSubmit = async (data) => {

//     const projects = discretionProject;
//     projects[0].amount = data.amount;

//     const payload = {
//       firstName: data.firstName,
//       lastName: data.lastName,
//       email: data.email,
//       contactNumber: data.phone,
//       address: "",
//       country: "",
//       website: "",
//       donationAmount: data.amount,
//       projects: projects.map((e, i) => ({
//         id: e.id ?? e._id,
//         name: e.name,
//         amount: e.amount,
//         charityId: e.charityDetails?.id ?? e.charityId,
//         charityName: "CharityPhilippines.org",
//       })),
//       discretion: {
//         isDiscretion: true,
//         causes: causes.includes('any') ? ['any'] : causes
//       }
//     }

//     DonationAPI.submitDonationMaya(payload)
//       .then((e) => {
//         const refId = e.data.data.insertedId;
//         const amount = data.amount;
//         const refDataParam = btoa(
//           JSON.stringify({
//             amount,
//             refId,
//             currency: "PHP",
//           })
//         );

//         const checkoutDetails = {
//           totalAmount: {
//             value: amount,
//             currency: "PHP",
//             details: {
//               discount: 0,
//               serviceCharge: 0,
//               shippingFee: 0,
//               subtotal: amount,
//               tax: 0,
//             },
//           },
//           buyer: {
//             firstName: data.firstName,
//             lastName: data.lastName,
//             contact: {
//               email: data.email,
//               phone: data.phone,
//             },
//           },
//           items: [{
//             name: "Discretionary Donation",
//             quantity: 1,
//             code: "discretionary-donation",
//             description: "Discretionary donation.",
//             amount: {
//               value: data.amount,
//               details: {
//                 discount: 0,
//                 serviceCharge: 0,
//                 shippingFee: 0,
//                 subtotal: data.amount,
//               },
//             },
//             totalAmount: {
//               value: data.amount,
//               details: {
//                 discount: 0,
//                 serviceCharge: 0,
//                 shippingFee: 0,
//                 tax: 0,
//                 subtotal: data.amount,
//               },
//             },
//           }],
//           redirectUrl: {
//             success: `${REDIRECT_URL}/${refDataParam}/success?redirectToHome=true`,
//             failure: `${REDIRECT_URL}/${refDataParam}/failed?redirectToHome=true`,
//             cancel: `${REDIRECT_URL}/${refDataParam}/cancelled?redirectToHome=true`,
//           },
//           requestReferenceNumber: e.data.data.insertedId,
//           // requestReferenceNumber: uuidv4()
//         };

//         localStorage.setItem("checkoutDetails", JSON.stringify(checkoutDetails));

//         // setTimeout(() => {
//         PaymayaSdkClient.createCheckout(checkoutDetails);
//         // }, 10000);
//       })
//       .catch((e) => {
//         alert("Encountered an error. Please try again.");
//       });

//     // const initiateMayaPayment = async (e) => {

//     // };
//   };

//   useEffect(() => {
//     if (values.anonymous) {
//       setValue('firstName', 'Anonymous');
//       setValue('lastName', 'Donor');
//       setValue('email', 'anonymousdonor@charityphilippines.org');
//       setValue('phone', '09111111111');
//     } else {
//       setValue('firstName', '');
//       setValue('lastName', '');
//       setValue('email', '');
//       setValue('phone', '');
//     }
//   }, [values.anonymous])

//   return (
//     <Stack
//       sx={{
//         width: "100%",
//         height: "100%",
//         background: "#f8f8f8",
//         borderRadius: "12px",
//       }}
//       p={4}
//     >
//       <Typography variant="h6">Card Payment</Typography>
//       <Typography mb={3} variant="body1">
//         Donate via Bank Deposit, Bank Transfer, or Wire Transfer to:
//       </Typography>
//       <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//         <Grid container spacing={2}>
//           <Grid item md={12}>
//             <Grid container alignItems="center">
//               <Checkbox
//                 onChange={(e) => setValue("anonymous", e.target.checked)}
//               />
//               <Typography variant="body1">Anonymous Donor</Typography>
//             </Grid>
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFTextField disabled={values.anonymous} name="firstName" label="First Name" />
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFTextField disabled={values.anonymous} name="lastName" label="last Name" />
//           </Grid>
//           {
//             !values.anonymous &&
//             <Grid item md={6} xs={12}>
//               <RHFTextField name="phone" label="Phone Number" />
//             </Grid>
//           }
//           <Grid item md={6} xs={12}>
//             <RHFTextField disabled={values.anonymous} name="email" label="Email" />
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFTextField name="amount" label="Donation Amount" />
//           </Grid>
//         </Grid>
//         <Button ref={ref} sx={{ display: "none" }} type="submit">
//           SUBMIT
//         </Button>
//       </FormProvider>
//     </Stack>
//   );
// });

// const BankTransfer = forwardRef(function ({ next, setIsLoading, causes }, ref) { // eslint-disable-line prefer-arrow-callback
//   const [selectedImages, setSelectedImages] = useState([]);

//   const resolver = yupResolver(
//     Yup.object().shape({
//       firstName: Yup.string().required("First name is required."),
//       lastName: Yup.string().required("Last name is required."),
//       contactNumber: Yup.string().required("Phone number is required."),
//       email: Yup.string().required("Email is required."),
//       address: Yup.string().required("Address is required."),
//       donationAmount: Yup.string().required("Donation amount is required."),
//       dobTransfer: Yup.string().required("Date of bank deposit/transfer is required."),
//       file: Yup.array().min(1, "Please select a photo")
//     })
//   );

//   const defaultValues = {
//     anonymous: false,
//     country: "PH",
//     file: [],
//     emailUpdate: false
//   };

//   const methods = useForm({ defaultValues, resolver });

//   const {
//     reset,
//     setError,
//     handleSubmit,
//     setValue,
//     trigger,
//     watch,
//     clearErrors,
//     formState: { errors, isSubmitting },
//   } = methods;

//   const values = watch();

//   const onSubmit = async (data) => {
//     setIsLoading(true);
//     const project = discretionProject;
//     project[0].amount = data.donationAmount;
//     let payload = {
//       firstName: data.firstName,
//       lastName: data.lastName,
//       email: data.email,
//       contactNumber: data.contactNumber,
//       address: data.address ? data.address : ' ',
//       country: data.country,
//       donationAmount: data.donationAmount,
//       dobTransfer: fDateDb(data.dobTransfer),
//       images: selectedImages,
//       // images: fileAttachments,
//       imagesBase64: [],
//       emailUpdate: data.emailUpdate,
//       charitySource: data.charitySource === 'Others' ? data.charityOtherSource : data.charitySource,
//       projects: project,
//       discretion: {
//         isDiscretion: true,
//         causes: causes.includes("any") ? ["any"] : causes
//       }
//     };
//     payload = await convertImagesToBase64STring(payload);
//     DonationAPI.submitDonationInHouse(DONATION_TYPE.IN_HOUSE.BANK_TRANSFER_DEPOSIT, payload)
//       .then((res) => {
//         next();
//         reset(defaultValues);
//         setIsLoading(false)
//       })
//       .catch((err) => {
//         alert(`Encountered an error. Please try again.${err}`);
//       });
//   };

//   useEffect(() => {
//     reset(defaultValues);
//   }, []);

//   useEffect(() => {
//     if (!selectedImages[0]) return;
//     setValue("file", selectedImages);
//     clearErrors('file');
//   }, [selectedImages]);

//   return (
//     <Stack
//       sx={{
//         width: "100%",
//         height: "100%",
//         background: "#f8f8f8",
//         borderRadius: "12px",
//       }}
//       p={4}
//     >
//       <Typography variant="h6">Bank Transfer/Deposit</Typography>
//       <Typography variant="body1">
//         Donate via Bank Deposit, Bank Transfer, or Wire Transfer to:
//       </Typography>
//       <Card sx={{ p: 2, mt: 2, mb: 2 }}>
//         <Typography variant="body2">
//           Account Name: CharityPhilippines.org
//         </Typography>
//         <Typography variant="body2">Account Number: 2447244520193</Typography>
//         <Typography variant="body2">
//           Bank Name: Metropolitan Bank and Trust Co. (Metrobank)
//         </Typography>
//         <Typography variant="body2">
//           Bank Address: Upper Ground Floor Farmers Plaza, Araneta Center, Quezon
//           City, Metro Manila, Philippines
//         </Typography>
//       </Card>
//       <Typography variant="body1" mb={2}>
//         Let us know about your donation by filling up donation details below.
//       </Typography>
//       <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//         <Grid container spacing={2}>
//           {/* <Grid item md={12}>
//             <Grid container alignItems="center">
//               <Checkbox
//                 onChange={(e) => setValue("anonymous", e.target.checked)}
//               />
//               <Typography variant="body1">Anonymous Donor</Typography>
//             </Grid>
//           </Grid> */}
//           <Grid item md={6} xs={12}>
//             <RHFTextField name="firstName" label="First Name" />
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFTextField name="lastName" label="last Name" />
//           </Grid>
//           <Grid item md={12}>
//             <RHFTextField name="email" label="Email Addess" />
//             <Typography variant="body2" p={1} color="gray">
//               We will send your donation receipt to email address provided.
//             </Typography>
//           </Grid>
//           <Grid item md={12} xs={12}>
//             <RHFTextField name="address" label="Addess" />
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFSelect
//               name="country"
//               label="Country"
//             // placeholder="Country"
//             >
//               <option value="" />
//               {countries.map((option) => (
//                 <option key={option.code} value={option.code}>
//                   {option.label}
//                 </option>
//               ))}
//             </RHFSelect>
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFTextField name="contactNumber" label="Contact Number" />
//             <Typography variant="body2" sx={{ fontSize: 12 }}>
//               We will contact you in case we encounter issues with your
//               donation.
//             </Typography>
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFTextField name="donationAmount" label="Donation Amount" />
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFDatePicker name="dobTransfer" label="Date of Bank Deposit/Transfer" />
//           </Grid>
//           <Grid item xs={12}>
//             <UploadPhoto
//               error={errors.file}
//               name="file"
//               label="Upload photo of deposit slip/screenshot of online banking transaction (optional)"
//               selectedImages={selectedImages ?? []}
//               setSelectedImages={setSelectedImages}
//             />
//           </Grid>
//         </Grid>
//         <Typography mt={5} variant="body1">
//           If you have questions and clarifications in partnering with CPO,
//           please send us a message
//           <Link href="/contact-us" target="_blank">
//             here
//           </Link>
//           or email us at contact@charityphilippines.org.
//         </Typography>
//         <Typography mt={2} variant="body1">
//           I am interested to receive CharityPhilippines.org's email updates
//           <Checkbox />
//         </Typography>
//         <Box display={"flex"} alignItems={"center"} mt={2} flexWrap={"wrap"}>
//           <Typography variant="body1" sx={{ mr: 2 }}>
//             Where did you hear about CharityPhilippines.org?
//           </Typography>
//           <Box xs={{ mr: 2, width: 220 }}>
//             <RHFSelect name="charitySource">
//               <option value="">Please Choose</option>
//               <option value="Family or Friend">Family or Friend</option>
//               <option value="Proactively Looking">Proactively Looking</option>
//               <option value="Workplace">Workplace</option>
//               <option value="Social Media">Social Media</option>
//               <option value="Social Media Advertising">
//                 Social Media Advertising
//               </option>
//               <option value="Others">Others</option>
//             </RHFSelect>
//           </Box>

//           {values.charitySource === "Others" && (
//             <Box width={220}>
//               <RHFTextField name="charityOtherSource" label="Specify Other" />
//             </Box>
//           )}
//         </Box>
//         <Button ref={ref} sx={{ display: "none" }} type="submit">
//           SUBMIT
//         </Button>
//       </FormProvider>
//     </Stack>
//   );
// });

// const Check = forwardRef(function ({ next, setIsLoading, causes }, ref) { // eslint-disable-line prefer-arrow-callback
//   const [selectedImages, setSelectedImages] = useState([]);

//   const resolver = yupResolver(
//     Yup.object().shape({
//       firstName: Yup.string().required("First name is required."),
//       lastName: Yup.string().required("Last name is required."),
//       contactNumber: Yup.string().required("Phone number is required."),
//       email: Yup.string().required("Email is required."),
//       address: Yup.string().required("Address is required."),
//       donationAmount: Yup.string().required("Donation amount is required."),
//       issuingBank: Yup.string().required("Issuing bank is required."),
//       checkDate: Yup.string().required("Check date is required."),
//     })
//   );

//   const defaultValues = {
//     anonymous: false,
//     country: "PH",
//     sendCheck: false,
//     pickup: false,
//     emailUpdate: false,
//     charitySource: "",
//     pickupAddress: "",
//   };

//   const methods = useForm({ defaultValues, resolver });

//   const {
//     reset,
//     setError,
//     handleSubmit,
//     setValue,
//     trigger,
//     watch,
//     clearErrors,
//     formState: { errors, isSubmitting },
//   } = methods;

//   const values = watch();

//   const onSubmit = (data) => {
//     setIsLoading(true);
//     const project = discretionProject;
//     project[0].amount = data.donationAmount;
//     const payload = {
//       firstName: data.firstName,
//       lastName: data.lastName,
//       email: data.email,
//       contactNumber: data.contactNumber,
//       address: data.address ? data.address : ' ',
//       country: data.country,
//       donationAmount: data.donationAmount,
//       issuingBank: data.issuingBank,
//       checkDate: data.checkDate ? fDateDb(data.checkDate) : '',
//       sendCheck: data.sendCheck,
//       pickup: data.pickup,
//       pickupAddress: data.pickupAddress === '' ? data.address : data.pickupAddress,
//       emailUpdate: data.emailUpdate,
//       charitySource: data.charitySource === 'Others' ? data.charityOtherSource : data.charitySource,
//       projects: project,
//       discretion: {
//         isDiscretion: true,
//         causes: causes.includes('any') ? ['any'] : causes
//       }
//     };

//     DonationAPI.submitDonationInHouse(DONATION_TYPE.IN_HOUSE.CHECK, payload)
//       .then((res) => {
//         next();
//         reset(defaultValues);
//         setIsLoading(false)
//       })
//       .catch((err) => {
//         alert(`Encountered an error. Please try again.${err}`);
//       });
//   };

//   useEffect(() => {
//     reset(defaultValues);
//   }, []);

//   useEffect(() => {
//     if (!selectedImages[0]) return;
//     setValue("file", selectedImages[0]);
//   }, [selectedImages]);

//   useEffect(() => {
//     if (!values.amount) return;
//     setValue("amount", values.amount.replace(/[^\d.]/g, ""));
//   }, [values.amount]);

//   return (
//     <Stack
//       sx={{
//         width: "100%",
//         height: "100%",
//         background: "#f8f8f8",
//         borderRadius: "12px",
//       }}
//       p={4}
//     >
//       <Typography variant="h6">Check</Typography>
//       <Typography variant="body1">
//         Please make your check payable to CharityPhilippines.org and send to:
//       </Typography>
//       <Card sx={{ p: 2, mt: 2, mb: 2 }}>
//         <Typography variant="body2">CharityPhilippines.org</Typography>
//         <Typography variant="body2">
//           Unit 204, Vivaldi Residences Condominium
//         </Typography>
//         <Typography variant="body2">
//           628 EDSA, Cubao, Quezon City, Metro Manila
//         </Typography>
//         <Typography variant="body2">Philippines 1109</Typography>
//       </Card>
//       <Typography variant="body1" mb={2}>
//         Let us know about your donation by filling up donation details below.
//       </Typography>
//       <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//         <Grid container spacing={2}>
//           {/* <Grid item md={12}>
//             <Grid container alignItems="center">
//               <Checkbox
//                 onChange={(e) => setValue("anonymous", e.target.checked)}
//               />
//               <Typography variant="body1">Anonymous Donor</Typography>
//             </Grid>
//           </Grid> */}
//           <Grid item md={6} xs={12}>
//             <RHFTextField name="firstName" label="First Name" />
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFTextField name="lastName" label="last Name" />
//           </Grid>
//           <Grid item md={12} xs={12}>
//             <RHFTextField name="email" label="Email Addess" />
//             <Typography variant="body2" p={1} color="gray">
//               We will send your donation receipt to email address provided.
//             </Typography>
//           </Grid>
//           <Grid item md={12} xs={12}>
//             <RHFTextField name="address" label="Addess" />
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFSelect
//               name="country"
//               label="Country"
//             // placeholder="Country"
//             >
//               <option value="" />
//               {countries.map((option) => (
//                 <option key={option.code} value={option.code}>
//                   {option.label}
//                 </option>
//               ))}
//             </RHFSelect>
//           </Grid>
//           <Grid item md={6} xs={12}>
//             {/* <Grid item md={12}> */}
//             <Stack>
//               <RHFTextField name="contactNumber" label="Contact Number" />
//               <Typography variant="body2" sx={{ fontSize: 12 }}>
//                 We will contact you in case we encounter issues with your
//                 donation.
//               </Typography>
//             </Stack>
//             {/* </Grid> */}
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFTextField name="donationAmount" label="Donation Amount" />
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFTextField name="issuingBank" label="Issuing Bank" />
//           </Grid>
//           <Grid item md={6} xs={12}>
//             <RHFDatePicker name="checkDate" label="Check Date" />
//           </Grid>
//           {/* <Grid item xs={12}>
//             <UploadPhoto
//               error={errors.file}
//               name="file"
//               label="Upload photo of deposit slip/screenshot of online banking transaction (optional)"
//               selectedImages={values.file ?? []}
//               setSelectedImages={setSelectedImages}
//             />
//           </Grid> */}
//         </Grid>
//         <Typography mt={5} variant="body1">
//           I will send my check to address provided.
//           <RHFCheckbox sx={{ ml: 2 }} name="sendCheck" label="" />
//           {/* <Checkbox checked={values.sendCheck} onChange={(e, checked) => {
//             setValue('sendCheck', checked);
//             setValue('pickup', false);
//           }} /> */}
//         </Typography>
//         <Typography mt={2} variant="body1">
//           I will request for check pick-up (Available in Metro Manila only.)
//           <RHFCheckbox sx={{ ml: 2 }} name="pickup" label="" />
//           {/* <Checkbox checked={values.pickup} onChange={(e, checked) => {
//             setValue('sendCheck', false);
//             setValue('pickup', checked);
//           }} /> */}
//         </Typography>
//         <Typography mt={2} variant="body1">
//           I will request for check pick-up in this address. (If not the
//           addressyou provided above.)
//           <RHFTextField sx={{ marginTop: 2 }} name="pickupAddress" />
//         </Typography>
//         <Typography mt={2} variant="body1">
//           If you have questions and clarifications in partnering with CPO,
//           please send us a message
//           <Link href="/contact-us" target="_blank">
//             {" here "}
//           </Link>
//           or email us at contact@charityphilippines.org.
//         </Typography>
//         <Typography mt={2} variant="body1">
//           I am interested to receive CharityPhilippines.org's email updates
//           <Checkbox onChange={(e, checked) => {
//             setValue('emailUpdate', checked);
//           }} />
//         </Typography>
//         <Box display={"flex"} alignItems={"center"} mt={2} flexWrap={"wrap"}>
//           <Typography variant="body1" sx={{ mr: 2 }}>
//             Where did you hear about CharityPhilippines.org?
//           </Typography>
//           <Box xs={{ mr: 2, width: 220 }}>
//             <RHFSelect name="charitySource">
//               <option value="">Please Choose</option>
//               <option value="Family or Friend">Family or Friend</option>
//               <option value="Proactively Looking">Proactively Looking</option>
//               <option value="Workplace">Workplace</option>
//               <option value="Social Media">Social Media</option>
//               <option value="Social Media Advertising">
//                 Social Media Advertising
//               </option>
//               <option value="Others">Others</option>
//             </RHFSelect>
//           </Box>

//           {values.charitySource === "Others" && (
//             <Box width={220}>
//               <RHFTextField name="charityOtherSource" label="Specify Other" />
//             </Box>
//           )}
//         </Box>
//         <Button ref={ref} sx={{ display: "none" }} type="submit">
//           SUBMIT
//         </Button>
//       </FormProvider>
//     </Stack>
//   );
// });

export default function DiscretionaryModal({ open, onClose }) {
  const [donationMethod, setDonationMethod] = useState("");
  const [giverType, setGiverType] = useState('');
  const [isRecurring, setIsRecurring] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  
  const host = process.env.PUBLIC_URL;

  useEffect(() => {
    if (isRecurring && paymentMethod !== 'online_payment') {
      setPaymentMethod('online_payment');
    }
  }, [isRecurring])

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open}>
        <DialogTitle style={{ maxWidth: "80%" }}>
          What kind of Giver are you?
          {/* <Typography>Donate generally to all active projects.</Typography> */}
          <IconButton
            aria-label="close"
            onClick={() => {
              setGiverType('');
              onClose();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflowX: "hidden" }}>
          <Box
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {xs: "column", md: "row"}}}
              justifyContent='center'
              alignItems='stretch'
            >

              <Grid xs item height={400}>
                <DiscretionOption
                  label="Universal Giver"
                  imgSrc={`${host}/images/universal_giver.png`}
                  description="Make a difference everywhere. Your donation will support projects across various causes we'll carefully select for you."
                  onClick={() => setGiverType('universal')}
                  active={giverType === 'universal'}
                  showArrows={giverType !== ''}
                />
              </Grid>

              <Grid xs item height={400}>
                <DiscretionOption
                  label="Balanced Giver"
                  imgSrc={`${host}/images/balanced_giver.png`}
                  description="Choose causes, and we'll distribute your donation equally among selected projects."
                  onClick={() => setGiverType('balanced')}
                  active={giverType === 'balanced'}
                  showArrows={giverType !== ''}
                />
              </Grid>

              <Grid xs item height={400}>
                <DiscretionOption
                  label="Custom Giver"
                  imgSrc={`${host}/images/custom_giver.png`}
                  description="Customize your support. Select causes and specify donation amounts; we'll allocate the funds to chosen projects within your causes."
                  onClick={() => setGiverType('custom')}
                  active={giverType === 'custom'}
                  showArrows={giverType !== ''}
                />
              </Grid>

            </Grid>

            {
              giverType !== '' &&
              <DonationForm
                discretionary
                giverType={giverType}
                projects={discretionProject}
                onSuccess={() => {
                  onClose();
                  setSuccessOpen(true)
                }}
              />
            }
          </Box>

        </DialogContent>
      </Dialog >
      <DonationSuccessModal open={successOpen} onClose={() => {
        setGiverType('');
        setSuccessOpen(false);
      }} />
    </>
  );
}


function DiscretionOption({
  label,
  imgSrc,
  description,
  onClick,
  active,
  showArrows
}) {
  return (
    <Box className={active ? 'active' : ''} borderRadius={2} p={2} pb='30px' sx={{
      cursor: 'pointer', '&': {
        height: '100%',
        position: 'relative',
        ' .arrow': {
          display: active ? 'block' : 'none'
        },
        ':hover': {
          ' .arrow': {
            display: 'block'
          }
        }
      }
    }
    } onClick={onClick}>
      <Box borderRadius={2} sx={{
        '&': {
          zIndex: 1,
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          right: 0,
          background: active ? '#EDFFF1 !important' : '#f2f2f2',
          border: active ? '3px solid #4CA75E !important' : '',
          ':hover': {
            border: '3px solid #B1E5BB',
            background: '#F8FFF9'
          }
        }
      }} />
      {
        showArrows ?
          <>
            <Box className="arrow" bgcolor='#FFF' width={70} height={70} position='absolute' bottom={-35} left='50%' sx={{ transform: 'translateX(-50%) rotate(45deg)', pointerEvents: 'none' }} zIndex={0} />
            <Box className="arrow" bgcolor={active ? '#EDFFF1' : '#F8FFF9'} width={50} height={50} position='absolute' bottom={-20.5} left='50%' sx={{ transform: 'translateX(-50%) rotate(45deg)', pointerEvents: 'none' }} zIndex={1} />
            <Box className="arrow" bgcolor={active ? '#EDFFF1' : '#F8FFF9'} border={active ? '3px solid #4CA75E' : '3px solid #B1E5BB'} width={50} height={50} position='absolute' bottom={-25} left='50%' sx={{ transform: 'translateX(-50%) rotate(45deg)', pointerEvents: 'none' }} zIndex={0} />
          </>
          : null
      }
      <Grid container alignItems='center' direction='column' height='100%' justifyContent='space-around' position='relative' sx={{ pointerEvents: 'none' }} zIndex={5}>
        <Grid item>
          <Typography fontWeight={700}>{label}</Typography>
        </Grid>
        <Grid item>
          <img src={imgSrc} alt='discretion-img' style={{ margin: '20px 0' }} />
        </Grid>
        <Grid item>
          <Typography align="center">{description}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function PaymentOption({
  label,
  imgSrc,
  onClick,
  selected,
  disabled
}) {
  return (
    <Grid container item xs alignItems='center' justifyContent='space-between' direction='column' sx={{ cursor: disabled ? 'not-allowed' : 'pointer', opacity: disabled ? .3 : 1, }} onClick={onClick}>
      <Grid item xs width='100%'>
        <Box width='100%' height='100%' bgcolor='white' p={2} borderRadius={1} >
          <Grid container item justifyContent='center' direction='column'>
            <img src={imgSrc} alt="payment-option" style={{ objectFit: 'contain' }} />
            <Typography align="center" mt={2} fontWeight={700}>{label}</Typography>
          </Grid>
        </Box>
      </Grid>
      <Radio checked={selected} sx={{ pointerEvents: 'none' }} />
    </Grid>
  );
}

function CardPaymentForm({ amount }) {

  const [isAnon, setIsAnon] = useState(false);
  const resolver = yupResolver(Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
  }))

  const defaultValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: ''
  }

  const methods = useForm({ defaultValues, resolver })

  const {
    reset,
    setError,
    handleSubmit,
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { errors, isSubmitting }
  } = methods;

  const values = watch();

  const onSubmit = async (data) => {
    // console.log(data);
  }

  const anonymous = {
    lastName: 'Donor',
    email: 'anonymousdonor@charityphilippines.org',
    phone: '09111111111'
  }

  useEffect(() => {
    if (isAnon) {
      reset(anonymous);
    } else {
      reset(defaultValues);
    }
  }, [isAnon])

  return (
    <Box>
      <Typography fontWeight={700}>Card Payment</Typography>
      {/* <Typography>Donate via Bank Deposit, Bank Transfer, or Wire Transfer to:</Typography> */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} direction='column'>
          <Grid item container alignItems='center' mt={3} sx={{ cursor: 'pointer' }} onClick={() => setIsAnon(!isAnon)}>
            <Grid item>
              <Checkbox checked={isAnon} onChange={() => setIsAnon(!isAnon)} />
            </Grid>
            <Grid item width='fit-content'>
              <Typography>Anonymous Donor</Typography>
            </Grid>
          </Grid>
          {
            isAnon ? null :
              <Grid item container spacing={2} justifyContent='space-between'>
                <Grid item xs={6}>
                  <RHFTextField name='firstName' fullWidth label="First Name" />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField name='lastName' fullWidth label="Last Name" />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField name='email' fullWidth label="Email" />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField name='phone' fullWidth label="Phone Number" />
                </Grid>
              </Grid>
          }
        </Grid>
        <Grid container justifyContent='center' py={5}>
          <Box width={300}>
            <Button fullWidth variant="contained" size="large" type="submit">SUBMIT</Button>
          </Box>
        </Grid>
      </FormProvider>
    </Box>
  )
}

function BankTransferForm({ amount }) {

  const [selectedImages, setSelectedImages] = useState([]);

  const resolver = yupResolver(Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    donationAmount: Yup.string().required('Required'),
    dobTransfer: Yup.string().required('Required'),
    file: Yup.array().min(1, "Please select a photo")
  }))

  const defaultValues = {
    anonymous: false,
    country: 'PH',
    file: [],
    emailUpdate: false,
  }

  const methods = useForm({ defaultValues, resolver });

  const {
    reset,
    setError,
    handleSubmit,
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = (data) => {

  }

  return (
    <Box>
      <Typography fontWeight={700}>Bank Transfer/Deposit</Typography>
      <Typography>Donate via Bank Deposit, Bank Transfer, or Wire Transfer to:</Typography>
      <Card sx={{ p: 2, mt: 2, mb: 2 }}>
        <Typography variant="body2">
          Account Name: CharityPhilippines.org
        </Typography>
        <Typography variant="body2">Account Number: 2447244520193</Typography>
        <Typography variant="body2">
          Bank Name: Metropolitan Bank and Trust Co. (Metrobank)
        </Typography>
        <Typography variant="body2">
          Bank Address: Upper Ground Floor Farmers Plaza, Araneta Center, Quezon
          City, Metro Manila, Philippines
        </Typography>
      </Card>
      <Typography>Let us know about your donation by filling up donation details below.</Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <RHFTextField fullWidth label="First Name" name='firstName' />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField fullWidth label="Last Name" name='lastName' />
          </Grid>
          <Grid item container spacing={1} xs={12}>
            <Grid item xs={12}>
              <RHFTextField fullWidth label="Email" name='email' />
            </Grid>
            <Grid item xs={12}>
              <Typography px={2} variant="caption">We will send your donation receipt to the email address provided.</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RHFTextField fullWidth label="Address" name='address' />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete value={countries.find(c => c.code === values.country)}
              fullWidth
              onChange={(ev, e) => setValue('country', e.code)} renderInput={(e) => <TextField label="Country" {...e} />}
              options={countries}
              getOptionLabel={(e) => e?.label ?? ''}
              isOptionEqualToValue={(a, b) => a.code === b}
            />
          </Grid>
          <Grid item xs={6}>
            <RHFDatePicker label="Date of Bank Transfer/Deposit" fullWidth name="dobTransfer" />
          </Grid>
          <Grid item xs={12}>
            <UploadPhoto
              error={errors.file}
              name="file"
              label="Upload photo of deposit slip/screenshot of online banking transaction (optional)"
              selectedImages={selectedImages ?? []}
              setSelectedImages={setSelectedImages}
            />
          </Grid>
        </Grid>
        <Typography mt={5} fontSize={16}>If you have questions and clarificaions in partnering with CPO, please send us a message <span>
          <Link href="/contact-us" target="_blank">
            here
          </Link>
        </span> or email us at contact@charityphilippines.org</Typography>
        <Grid mt={2} container alignItems="center">
          <Typography fontSize={16}>I am interested to receive CharityPhilippines.org's email updates</Typography>
          <Checkbox />
        </Grid>
        <Grid container alignItems="center" mt={2}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Where did you hear about CharityPhilippines.org?
          </Typography>
          <Grid item xs={3}>
            <Select value={values.charitySource ?? "_"} fullWidth onChange={(e) => setValue('charitySource', e.target.value)}>
              <MenuItem value="_">Please Choose</MenuItem>
              <MenuItem value="Family or Friend">Family or Friend</MenuItem>
              <MenuItem value="Proactively Looking">Proactively Looking</MenuItem>
              <MenuItem value="Workplace">Workplace</MenuItem>
              <MenuItem value="Social Media">Social Media</MenuItem>
              <MenuItem value="Social Media Advertising">
                Social Media Advertising
              </MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {values.charitySource === "Others" && (
          <Box width={220}>
            <RHFTextField name="charityOtherSource" label="Specify Other" />
          </Box>
        )}
        <Grid container justifyContent='center' py={5}>
          <Grid item xs={4}>
            <Button fullWidth size="large" variant="contained">SUBMIT</Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  )
}

function CheckForm({ amount }) {

  const [selectedImages, setSelectedImages] = useState([]);

  const resolver = yupResolver(Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    donationAmount: Yup.string().required('Required'),
    dobTransfer: Yup.string().required('Required'),
    file: Yup.array().min(1, "Please select a photo")
  }))

  const defaultValues = {
    anonymous: false,
    file: [],
    country: 'PH',
    emailUpdate: false,
  }

  const methods = useForm({ defaultValues, resolver });

  const {
    reset,
    setError,
    handleSubmit,
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = (data) => {

  }

  return (
    <Box>
      <Typography fontWeight={700}>Check</Typography>
      <Typography>Please make your check payable to CharityPhilippines.org and send to:</Typography>
      <Card sx={{ p: 2, mt: 2, mb: 2 }}>
        <Typography variant="body2">
          Account Name: CharityPhilippines.org
        </Typography>
        <Typography variant="body2">Account Number: 2447244520193</Typography>
        <Typography variant="body2">
          Bank Name: Metropolitan Bank and Trust Co. (Metrobank)
        </Typography>
        <Typography variant="body2">
          Bank Address: Upper Ground Floor Farmers Plaza, Araneta Center, Quezon
          City, Metro Manila, Philippines
        </Typography>
      </Card>
      <Typography>Let us know about your donation by filling up donation details below.</Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <RHFTextField fullWidth label="First Name" name='firstName' />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField fullWidth label="Last Name" name='lastName' />
          </Grid>
          <Grid item container spacing={1} xs={12}>
            <Grid item xs={12}>
              <RHFTextField fullWidth label="Email" name='email' />
            </Grid>
            <Grid item xs={12}>
              <Typography px={2} variant="caption">We will send your donation receipt to the email address provided.</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RHFTextField fullWidth label="Address" name='address' />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete value={countries.find(c => c.code === values.country)}
              fullWidth
              onChange={(ev, e) => setValue('country', e.code)} renderInput={(e) => <TextField label="Country" {...e} />}
              options={countries}
              getOptionLabel={(e) => e?.label ?? ''}
              isOptionEqualToValue={(a, b) => a.code === b.code}
            />
          </Grid>
          <Grid item container xs={6} direction='column' alignItems='start'>
            <RHFTextField fullWidth name='phone' label='Contact Number' />
            <Typography variant="caption">We will contact you in case we encounter issues with your donation.</Typography>
          </Grid>
          <Grid item xs={6}>
            <RHFDatePicker label="Check Date" fullWidth name="checkDate" />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField fullWidth name='issuingBank' label='Issuing Bank' />
          </Grid>
        </Grid>
        <Grid mt={2} container alignItems="center">
          <Typography mt={5} fontSize={16}>I will send my check to the address provided.</Typography>
          <Checkbox checked={values.sendCheck} />
        </Grid>
        <Grid mt={2} container alignItems="center">
          <Typography fontSize={16}>I will request for check pick-up (Available in Metro Manila only.)</Typography>
          <Checkbox checked={values.pickup} />
        </Grid>
        <Typography mt={2} fontSize={16}>I will request for check pick-up in this address. (If not the address you provided above.)</Typography>
        <RHFTextField fullWidth name='pickupAddress' />
        <Grid container alignItems="center" mt={2}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Where did you hear about CharityPhilippines.org?
          </Typography>
          <Grid item xs={3}>
            <Select value={values.charitySource ?? "_"} fullWidth onChange={(e) => setValue('charitySource', e.target.value)}>
              <MenuItem value="_">Please Choose</MenuItem>
              <MenuItem value="Family or Friend">Family or Friend</MenuItem>
              <MenuItem value="Proactively Looking">Proactively Looking</MenuItem>
              <MenuItem value="Workplace">Workplace</MenuItem>
              <MenuItem value="Social Media">Social Media</MenuItem>
              <MenuItem value="Social Media Advertising">
                Social Media Advertising
              </MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {values.charitySource === "Others" && (
          <Box width={220}>
            <RHFTextField name="charityOtherSource" label="Specify Other" />
          </Box>
        )}
        <Grid container justifyContent='center' py={5}>
          <Grid item xs={4}>
            <Button fullWidth size="large" variant="contained">SUBMIT</Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  )
}

// function CauseSelector({ causes, onChange }) {

//   const [selectedCauses, setSelectedCauses] = useState([]);
//   const [anchor, setAnchor] = useState(null);
//   const open = Boolean(anchor);

//   useEffect(() => {
//     if (onChange) {
//       onChange(selectedCauses);
//     }
//   }, [selectedCauses])

//   return (
//     <Grid container bgcolor='white' borderRadius={1} pb={2} my={5} spacing={2} alignItems='center'>
//       {
//         selectedCauses.map(e => (
//           <Grid item>
//             <Box p={1} borderRadius={1} bgcolor='#00AB55'>
//               <Grid spacing={1} container alignItems='center'>
//                 <Grid item>
//                   <Typography color='white'>{e.name}</Typography>
//                 </Grid>
//                 <Grid item alignItems='center'>
//                   <Button sx={{ minWidth: 'unset' }} onClick={() => setSelectedCauses(selectedCauses.filter(sc => sc.name !== e.name))}>
//                     <Icon icon='mingcute:close-line' color="black" />
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Grid>
//         ))
//       }
//       {selectedCauses.length !== causes.length &&
//         <Grid item>
//           <Button id="basic-button" variant="contained" sx={{ minWidth: 'unset', width: 40, height: 40, padding: 0 }} onClick={(e) => setAnchor(e.currentTarget)} aria-controls={open ? 'basic-menu' : undefined}>
//             <Icon icon='mingcute:add-line' fontSize={16} />
//           </Button>
//           <Menu
//             open={open}
//             anchorEl={anchor}
//             onClose={() => setAnchor(null)}
//             MenuListProps={{
//               "aria-labelledby": 'basic-button',
//             }}
//             PaperProps={{
//               style: {
//                 maxHeight: 300
//               }
//             }}
//           >
//             {causes &&
//               causes
//                 .filter(c => !selectedCauses.map(sc => sc.name).includes(c.name))
//                 .map(c => <MenuItem onClick={() => {
//                   setSelectedCauses([...selectedCauses, c])
//                   setAnchor(null)
//                 }}>{c.name}</MenuItem>)
//             }
//           </Menu>
//         </Grid>
//       }
//       {
//         selectedCauses.length === 0 &&
//         <Grid item>
//           <Typography color='#ccc'>Add a cause</Typography>
//         </Grid>
//       }
//     </Grid >
//   )
// }

// function CauseSelectorWithPrice({ causes }) {

//   const [selectedCauses, setSelectedCauses] = useState([]);
//   const [anchor, setAnchor] = useState(null);
//   const [currency, setCurrency] = useState('PHP')
//   const open = Boolean(anchor);

//   return (
//     <Box>
//       <Grid container spacing={2} direction='column' my={5}>
//         {selectedCauses.map((e, i) =>
//           <Grid item container>
//             <Grid item container xs={11} borderRadius={1} overflow='hidden' border='1px solid #ddd'>
//               <Grid item xs={4} p={2} bgcolor='white'>
//                 <Typography>{e.cause.name}</Typography>
//               </Grid>
//               <Grid item container xs={8} bgcolor='#F3F8F4' alignItems='center' pl={2}>
//                 <Grid item xs={1}>
//                   <Typography>{currency ?? 'PHP'}</Typography>
//                 </Grid>
//                 <Grid item xs={11}>
//                   <TextField type="number" value={e.amount} fullWidth placeholder="0.00" prefix="PHP" variant="standard" InputProps={{
//                     disableUnderline: true
//                   }} onChange={(e) => {
//                     const selected = [...selectedCauses];
//                     selected[i].amount = e.target.value;
//                     setSelectedCauses(selected);
//                   }} />
//                 </Grid>
//               </Grid>
//             </Grid>
//             <Grid item xs={1} p={2} >
//               <Button sx={{ minWidth: 'unset' }} onClick={() => setSelectedCauses(selectedCauses.filter(sc => sc.name !== e.cause.name))}>
//                 <Icon icon='mingcute:close-line' color="black" />
//               </Button>
//             </Grid>
//           </Grid>
//         )}
//         {

//           selectedCauses.length !== causes?.length &&
//           <Grid item container alignItems='center' spacing={2}>
//             <Grid item>
//               <Button id="basic-button" variant="contained" sx={{ minWidth: 'unset', width: 40, height: 40, padding: 0 }} onClick={(e) => setAnchor(e.currentTarget)} aria-controls={open ? 'basic-menu' : undefined}>
//                 <Icon icon='mingcute:add-line' fontSize={16} />
//               </Button>
//               <Menu
//                 open={open}
//                 anchorEl={anchor}
//                 onClose={() => setAnchor(null)}
//                 MenuListProps={{
//                   "aria-labelledby": 'basic-button',
//                 }}
//                 PaperProps={{
//                   style: {
//                     maxHeight: 300
//                   }
//                 }}
//               >
//                 {causes &&
//                   causes
//                     .filter(c => !selectedCauses.map(sc => sc.name).includes(c.name))
//                     .map(cause => <MenuItem onClick={() => {
//                       setSelectedCauses([...selectedCauses, { cause }])
//                       setAnchor(null)
//                     }}>{cause.name}</MenuItem>)
//                 }
//               </Menu>
//             </Grid>
//             {
//               selectedCauses.length === 0 &&
//               <Grid item>
//                 <Typography color='#ccc'>Add a cause</Typography>
//               </Grid>
//             }
//           </Grid>
//         }
//         <Grid container justifyContent='center' mt={5}>
//           <Grid item>
//             <Box height='100%' width='100%' bgcolor='white' p={2} border="#ccc 1px solid">
//               <Grid container justifyContent='space-around' alignItems='center' spacing={2}>
//                 <Grid item>
//                   <Typography variant="h5">Total Donation Amount:</Typography>
//                 </Grid>
//                 <Grid item>
//                   <Typography variant="h5">
//                     <Select value={currency} inputProps={{ sx: { bgcolor: 'white' } }} onChange={(e) => setCurrency(e.target.value)} style={{ marginRight: 20 }}>
//                       <MenuItem value="PHP" >PHP</MenuItem>
//                       <MenuItem value="USD">USD</MenuItem>
//                     </Select>
//                     {Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: "PHP" }).format(selectedCauses.reduce((prev, val, i) => prev + parseFloat(val.amount || '0'), 0)) || 0}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Box>
//   )
// }